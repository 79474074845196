<!--
 * @Date: 2021-10-07 15:25:27
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-10-07 15:25:53
 * @Description:
 * @FilePath: \maintenance_expert\src\views\SystemSettings\role\components\JZDrawer.vue
-->
<template>
  <el-drawer  :title="currentTitle"
    :visible.sync="isShow"
    destroy-on-close
    :wrapperClosable='false'
    size="800px"
  >
    <div class="role_drawer">
      <el-form class="content_block" label-width="100px" label-position="right" ref="JZForm" :model="JZForm" :rules="roleDetailModelRule">
        <el-form-item label="角色名称：" prop="name">
          <el-input placeholder="请输入角色名称" :readonly="disabled" v-model.trim="JZForm.name" maxlength="10" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="角色描述：" prop='description'>
          <el-input  placeholder="请输入角色描述" :readonly="disabled" :maxlength="50" show-word-limit v-model.trim="JZForm.description"></el-input>
        </el-form-item>
      </el-form>
      <div class="role_drawer_table content_block">
        <vxe-table
          height="100%"
          ref="roleTree"
          resizable
          border="full"
          size="small"
          header-align="center"
          highlight-current-row
          highlight-hover-row
          highlight-current-column
          highlight-hover-column
          row-id="id"
          :tree-config="{children: 'children',expandAll: true}"
          :data="roleDetailTree">
          <vxe-column tree-node>
             <template #header>
              <el-checkbox :disabled="disabled" v-model="isCheckedAll">菜单资源</el-checkbox>
             </template>
            <template #default="{ row }">
              <el-checkbox :disabled="disabled" v-model="row.isChecked" @change="handleRoleMenuChanged(row,$event)">{{row.name}}</el-checkbox>
            </template>
          </vxe-column>
          <vxe-column field="date" title="数据权限">
            <template #default="{ row }">
              <el-radio-group v-model="row.dataTagId" :disabled="disabled" @change="handleDatascopeChange(row,$event)">
                <el-radio :label="i.id" v-for="i in row.dataTagRespDTOList" :key="i.id">{{i.name}}</el-radio>
              </el-radio-group>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <div class="role_drawer_footer">
        <el-button @click="isShow=false">取消</el-button>
        <el-button v-if="isStatus!==3" type="primary" @click="handleAddOrUpdateRole">{{isStatus==2?'更新':'保存'}}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import SystemSetting from '@/api/systemSetting';
import { flattenTree} from '@/utils/menuTree';
import { mapActions } from 'vuex';
export default {
  name: 'JZDrawer',
  computed: {
    currentTitle () {
      let isStatus = this.isStatus;
      return isStatus === 3 ? '角色权限详情' : isStatus === 2 ? '编辑角色权限' : isStatus === 1 ? '新增角色权限' : '';
    },
    isCheckedAll: {
      get: function () {
        return Object.keys(this.roleMenuMap).length > 0 && Object.keys(this.roleMenuMap).length === Object.keys(this.roleMenuMap).filter(t=>this.roleMenuMap[t].isChecked).length;
      },
      set: function (val) {
        Object.keys(this.roleMenuMap).forEach(t=>{
          this.$set(this.roleMenuMap[t], 'isChecked', val);
        });
      },
    },
  },
  methods: {
    ...mapActions([
      'getMenuInfo',
    ]),
    init () {
      this.JZForm.name = '';
      this.JZForm.description = '';
      this.isShow = true;
      this.getRoleDetailTree();
    },
    getRoleDetailTree () {
      SystemSetting.getProductMenuTree().then(res =>{
        this.roleMenuMap = flattenTree(res.body || []).treeMap;
        this.roleDetailTree = res.body || [];
        if (this.JZForm.id) {
          this.handleDetail(this.JZForm.id);
        }
        this.$nextTick(()=>{
          this.$refs.roleTree.setAllTreeExpand(true);
        });
      });
    },
    handleDetail (id) {
      SystemSetting.getDetailRole({id: id, findRelMenuListFlag: true, findRelMenuTreeFlag: true}).then(this.getDetailAccountSuccessFun);
    },
    getDetailAccountSuccessFun (jsonData) {
      if (jsonData.heads.code === 200) {
        let accountInfo = jsonData.body;
        this.JZForm = {
          name: accountInfo.name,
          description: accountInfo.description,
          id: accountInfo.id,
        };
        let menuRespDTOList = jsonData.body.menuRespDTOList;
        let roleMenuMap = flattenTree(this.roleDetailTree || []).treeMap;
        menuRespDTOList.forEach(r =>{
          let menu = roleMenuMap[r.id];
          if (menu) {
            this.$set(menu, 'isChecked', true);
            this.$set(menu, 'dataTagId', r.dataTagId);
          }
        });
        this.roleMenuMap = roleMenuMap;
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
    },
    handleAddOrUpdateRole () {
      let _this = this;
      this.$refs.JZForm.validate(valid=>{
        if (valid) {
          let selectedMenus = [];
          Object.keys(_this.roleMenuMap).forEach(t=>{
            if (_this.roleMenuMap[t].isChecked === true) {
              selectedMenus.push(_this.roleMenuMap[t]);
            }
          });
          if (!selectedMenus || !selectedMenus[0]) {
            _this.$JZShowAlert('请选择菜单资源', 'error');
            return;
          }

          if (selectedMenus.some(t=>t.dataTagRespDTOList && t.dataTagRespDTOList[0] && !t.dataTagId)) {
            _this.$JZShowAlert('请选择菜单资源对应的数据权限', 'error');
            return;
          }
          selectedMenus = selectedMenus.map(item=>{
            return {
              id: item.id,
              dataTagId: item.dataTagId,
            };
          });
          let action = _this.JZForm.id ? SystemSetting.updateRole({..._this.JZForm, menuReqDTOList: selectedMenus}) : SystemSetting.addRole({..._this.JZForm, menuReqDTOList: selectedMenus});
          action.then((res)=>{
            console.log('hello:', res);
            if (res.heads.code === 200) {
              _this.isShow = false;
              _this.$emit('handleSearch');
              this.getMenuInfo();
            } else {
              _this.$JZShowAlert(res.heads.message, 'error');
            }
          });
        }
      });
    },
    handleRoleMenuChanged (row, val) {
      let setAfterNodeChecked = (node, isChecked) => {
        this.$set(node, 'isChecked', isChecked);
        node.children && node.children.forEach(n => {
          setAfterNodeChecked(n, isChecked);
        });
      };
      let setPreNodeChecked = (node, isChecked) => {
        let parentNode = this.roleMenuMap[node.pid];
        if (!parentNode) return;
        if (isChecked) {
          // 若选中，需要递归往上确认父节点是否需要选中
          this.$set(parentNode, 'isChecked', isChecked);
          setPreNodeChecked(parentNode, isChecked);
        } else {
          // 若取消选中，需要判断父节点是否选中，进行取消动作
          if (parentNode.isChecked && parentNode.children.every(t => !t.isChecked)) {
            this.$set(parentNode, 'isChecked', isChecked);
            setPreNodeChecked(parentNode, isChecked);
          }
        }
      };
      // 新增角色/修改角色 ：菜单权限取消勾选后，数据权限应同步取消选中
      if (!val) {
        this.$set(row, 'dataTagId', '');
      }
      setAfterNodeChecked(row, val);
      // 按钮操作权限不影响菜单资源选中
      if (row.type !== 3) {
        setPreNodeChecked(row, val);
      }
    },
    handleDatascopeChange (row) {
      if (!row.isChecked) {
        this.$set(row, 'isChecked', true);
        this.handleRoleMenuChanged(row, true);
      }
    },
  },
  data () {
    return {
      JZForm: {
        name: '',
        description: '',
        id: '',
      },
      isStatus: '',
      isShow: false,
      roleMenuMap: {},
      roleDetailTree: [],
      roleDetailModelRule: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { max: 10, message: '角色名称长度10个字符', trigger: 'blur' },
        ],
      },
      disabled: false,
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.role_drawer_footer{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: right;
  padding-right: 10px;
}
.role_drawer {
  height: 100%;
  display: flex;
  flex-direction: column;

  &>.role_drawer_table{
    flex: 1;
  }

  &>.role_drawer_footer{
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
  }
}
</style>
